import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import Modal from './Modal';
import EditStructure from './EditStructure';
import ImportTab from './ImportTab';
import EtatFinStructure from './EtatFinStructure';
import DonneesTab from './DonneesTab';
import Header from '../Header';
import { getReponseApi } from '../apis/api_backend';
import { Building2, FileUp, Database, Calendar, MapPin, Hash, School, Edit, Wallet } from 'lucide-react';

import '../styles/UneStructure.css';

function UneStructure() {
  const { user, structure, setStructure } = useContext(PayEcoleContext);
  const [activeTab, setActiveTab] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localStructure, setLocalStructure] = useState(null);
  const [editKey, setEditKey] = useState(0); // Ajouter ce state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStructure = async () => {
      try {
        if (user && user.id_structure && !structure) {
          setLoading(true);
         
          const response = await getReponseApi(`/structures/${user.id_structure}`, 'GET');

          if (response && Array.isArray(response) && response.length > 0) {
            const structureData = response[0];
            
            setStructure(structureData);
            setLocalStructure(structureData);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de la structure:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!structure && user && user.id_structure) {
      console.log('Chargement de la structureActuelle');
      fetchStructure();
    }
  }, [structure, user, setStructure]);
  const goToConvention = () => {
    navigate('/convention');
  };

  
  const renderTabContent = () => {
    // Get the current structure data from either context or local state
    const currentStructure = structure || localStructure;

    // Only render content if we have structure data and not loading
    if (loading) {
      return (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Chargement des informations...</p>
        </div>
      );
    }

    if (!currentStructure) {
      return <div>Structure non disponible</div>;
    }

    switch (activeTab) {
      case 0:
        return renderInfosStructure(currentStructure);
      case 1:
        return <ImportTab structure={currentStructure} />;
      case 2:
        return <DonneesTab structure={currentStructure} />;
      case 3:
        return <EtatFinStructure structure={currentStructure} />;
      default:
        return null;
    }
  };

  const renderInfosStructure = () => {
  
    if (loading) {
      return (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Chargement des informations...</p>
        </div>
      );
    }

    const currentStructure = structure || localStructure;
    if (!currentStructure) return <div>Structure non disponible</div>;

    return (
      <div className="structure-card">
            <div className="structure-header">
                <div className="structure-logo-container">
                  {currentStructure.logo ? (
                    <img src={currentStructure.logo} alt="Logo" className="structure-logo" />
                  ) : (
                    <div className="structure-logo-placeholder">
                      <Building2 size={40} />
                    </div>
                  )}
                </div>
                  <h2 className="structure-title">{currentStructure.nom_structure}</h2>
                  {/* Boutons de téléchargement des guides */}
                  <div className="structure-pdf-button">
                    <a
                      href={`${process.env.PUBLIC_URL}/documents/guide_clients.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download="Guide Clients"
                    >
                      <FileUp size={25} />
                      <span>Guide Clients:PDF</span>
                    </a>
                  </div>
                  <div className="structure-pdf-button">
                    <a
                      href={`${process.env.PUBLIC_URL}/documents/guide_gerant.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download="Guide Gérant"
                    >
                      <FileUp size={25} />
                      <span>Guide Gérant:PDF</span>
                    </a>
                  </div>
                </div>

        <div className="structure-details">
          <div className="detail-item">
            <Hash className="detail-icon" />
            <div className="detail-content">
              <label>Code</label>
              <span>{currentStructure.code_structure}</span>
            </div>
          </div>

          <div className="detail-item">
            <MapPin className="detail-icon" />
            <div className="detail-content">
              <label>Adresse</label>
              <span>{currentStructure.adresse || 'Non renseigné'}</span>
            </div>
          </div>

          <div className="detail-item">
            <School className="detail-icon" />
            <div className="detail-content">
              <label>Type</label>
              <span>{currentStructure.type_structure || 'Non renseigné'}</span>
            </div>
          </div>

          <div className="detail-item">
            <Calendar className="detail-icon" />
            <div className="detail-content">
              <label>Date de la création</label>
              <span>
                {currentStructure.createdat
                  ? new Date(currentStructure.createdat).toLocaleDateString()
                  : 'Non renseigné'}
              </span>
            </div>
          </div>
        </div>
        <button className="edit-button" onClick={() => goToConvention()}>
          <Edit size={16} />
          <span>Convention</span>
        </button>

        <button className="edit-button" onClick={() => setShowEdit(true)}>
          <Edit size={16} />
          <span>Modifier</span>
        </button>
      </div>
    );
  };

  const handleEditClose = async (updated = false) => {
  setShowEdit(false);
  
  if (updated) {
    try {
      // Récupérer les données mises à jour
      const response = await getReponseApi(`/structures/${user.id_structure}`, 'GET');
      
      if (response && Array.isArray(response) && response.length > 0) {
        const updatedStructure = response[0];
        setStructure(updatedStructure);
        setLocalStructure(updatedStructure);
        // Incrémenter editKey pour forcer le re-render du composant EditStructure
        setEditKey(prev => prev + 1);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des informations:', error);
    }
  }
};

  return (
    <div className="structure-container">
      <Header />
      <div className="tabs-container">
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 0 ? 'active' : ''}`} 
            onClick={() => setActiveTab(0)}
          >
            <Building2 size={18} />
            Infos
          </button>
          <button 
            className={`tab ${activeTab === 1 ? 'active' : ''}`} 
            onClick={() => setActiveTab(1)}
          >
            <FileUp size={18} />
            Charger données
          </button>
          <button 
            className={`tab ${activeTab === 2 ? 'active' : ''}`} 
            onClick={() => setActiveTab(2)}
          >
            <Database size={18} />
            Produits & Services
          </button>
          <button 
        className={`tab ${activeTab === 3 ? 'active' : ''}`} 
        onClick={() => setActiveTab(3)}
      >
        <Wallet size={18} />
        Finance
      </button>
        </div>
      </div>
      
      <div className="tab-content">
        {renderTabContent({structure })}
      </div>

      {showEdit && (
        <Modal 
          onClose={() => handleEditClose(false)}
          title={`Modifier ${(structure || localStructure)?.nom_structure || 'la structure'}`}
        >
          <EditStructure
            key={editKey}
            structure={structure || localStructure}
            onClose={(updated) => handleEditClose(updated)}
          />
        </Modal>
)}
    </div>
  );
}

export default UneStructure;