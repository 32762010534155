import React, { useState, useEffect, useMemo,useContext } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { X, Copy } from 'lucide-react';
import '../styles/EditFactureModal.css';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';

// Composant pour le modal de sélection du nombre de factures
const DuplicateModal = ({ isOpen, onClose, onConfirm }) => {
  const [nombreFactures, setNombreFactures] = useState(1);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nombreFactures < 1) {
      setError('Le nombre de factures doit être au moins 1');
      return;
    }
    onConfirm(nombreFactures);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlayfk">
      <div className="modal-contentfk" style={{ maxWidth: '400px' }}>
        <button onClick={onClose} className="btn-close">
          <X size={18} />
        </button>
        
        <h2 className="text-xl font-semibold mb-4">Dupliquer la facture</h2>
        
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-group">
            <label htmlFor="nombreFactures">Nombre de factures à générer</label>
            <input
              type="number"
              id="nombreFactures"
              min="1"
              value={nombreFactures}
              onChange={(e) => setNombreFactures(parseInt(e.target.value))}
              required
            />
            {error && <span className="error-message">{error}</span>}
          </div>
          
          <div className="modal-actions">
            <button type="button" className="cancel-button" onClick={onClose}>
              Annuler
            </button>
            <button type="submit" className="submit-button">
              Confirmer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Composant principal EditFactureModal mis à jour
const EditFactureModal = ({ facture, isOpen, onClose, onUpdate, isLoading }) => {
  const [formData, setFormData] = useState({
    nannee: '',
    nmois: '',
    nom_classe: '',
    tel_client: '',
    nom_client_payeur: '',
    montant: '',
    id_etat: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const { structure } = useContext(PayEcoleContext);

  useEffect(() => {
    if (facture) {
      setFormData({
        nannee: facture.nannee || '',
        nmois: facture.nmois || '',
        nom_classe: facture.nom_classe || '',
        tel_client: facture.tel_client || '',
        nom_client_payeur: facture.nom_client || '',
        montant: facture.montant || '',
        id_etat: facture.id_etat || ''
      });
    }
  }, [facture]);

  const isFormValid = useMemo(() => {
    const errors = {};
    if (!formData.nom_classe?.trim()) {
      errors.nom_classe = 'L\'article est requis';
    }
    if (!formData.montant || formData.montant <= 0) {
      errors.montant = 'Le montant doit être supérieur à 0';
    }
    if (formData.tel_client && !/^\d{9}$/.test(formData.tel_client)) {
      errors.tel_client = 'Le numéro de téléphone doit contenir 9 chiffres';
    }
    return Object.keys(errors).length === 0;
  }, [formData]);

  const handleDuplicate = async (nombreFactures) => {
    setIsDuplicateModalOpen(false);
    setLoading(true);
    setError('');

    try {
      // Formatage des données selon la structure attendue par l'API
      const requestData = {
        id_structure: facture?.id_structure,
        nom_produit: formData.nom_classe,
        nom_client: formData.nom_client_payeur || 'Pas defini',
        tel_client: formData.tel_client || 'Pas defini',
        montant: parseFloat(formData.montant),
        factures: parseInt(nombreFactures)
      };


      const response = await getReponseApi('/structures/create_one', 'POST', requestData);
      
      if (response?.message === 'Enregistrement réussi') {
        alert(`Enregistrement réussi - ${response.details.facturesGenerees} facture(s) générée(s)`);
        onClose();
      } else {
        throw new Error(response?.message || 'La duplication a échoué');
      }
    } catch (err) {
      console.error('Erreur détaillée:', err);
      let errorMessage = err.message;
      if (err.message.includes('400')) {
        errorMessage = 'Veuillez vérifier les données du formulaire';
      }
      setError(errorMessage || 'Une erreur est survenue lors de la duplication');
    } finally {
      setLoading(false);
    }
  };

  // Reste du code existant pour handleChange et handleSubmit...
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError('Veuillez corriger les erreurs du formulaire');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await getReponseApi(
        `/factures/updateFacture/${facture.id_facture}`,
        'PUT',
        formData
      );

      if (response && response.id_facture) {
        await onUpdate(facture.id_facture, response);
        onClose();
      } else {
        throw new Error('La mise à jour a échoué');
      }
    } catch (err) {
      setError(err.message || 'Une erreur est survenue lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
   <div className="modal-overlayfk">
    <div className="modal-contentfk">
      <div className="modal-header">
        <h2>Modifier la facture</h2>
        <button onClick={onClose} className="btn-close">
          <X size={18} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="modal-form">
        {error && <div className="error-message">{error}</div>}
        
        <button
          type="button"
          hidden = {structure?.id_type === 1}
          onClick={() => setIsDuplicateModalOpen(true)}
          className="btnDuplicate"
        >
          <Copy size={18} />
          Dupliquer cette facture
        </button>

        {/* Année et Mois restent toujours côte à côte */}
        <div className="form-row keep-together date-fields">
          <div className="form-group">
            <label htmlFor="nannee">Année</label>
            <input
              type="number"
              id="nannee"
              name="nannee"
              value={formData.nannee}
              onChange={handleChange}
              min="2000"
              max="2100"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="nmois">Mois</label>
            <select
              id="nmois"
              name="nmois"
              value={formData.nmois}
              onChange={handleChange}
              required
            >
              <option value="">Sélectionner</option>
              {[...Array(12)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString('fr-FR', { month: 'long' })}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Article prend toute la largeur */}
        <div className="form-group full-width">
          <label htmlFor="nom_classe">Article</label>
          <input
            type="text"
            id="nom_classe"
            name="nom_classe"
            value={formData.nom_classe}
            onChange={handleChange}
            required
          />
        </div>

        {/* État et Montant côte à côte */}
        <div className="form-row keep-together">
          <div className="form-group">
            <label htmlFor="id_etat">État</label>
            <select
              id="id_etat"
              name="id_etat"
              value={formData.id_etat}
              onChange={handleChange}
              required
            >
              <option value="1">En attente</option>
              <option value="2">Payée</option>
              <option value="3">Annulée</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="montant">Montant</label>
            <input
              type="number"
              id="montant"
              name="montant"
              value={formData.montant}
              onChange={handleChange}
              min="0"
              step="0.01"
              required
            />
          </div>
        </div>

        {/* Téléphone et Nom du client côte à côte */}
        <div className="form-row keep-together">
          <div className="form-group">
            <label htmlFor="tel_client">Téléphone client</label>
            <input
              type="text"
              id="tel_client"
              name="tel_client"
              value={formData.tel_client}
              onChange={handleChange}
              pattern="\d{9}"
              title="Le numéro doit contenir exactement 9 chiffres"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="nom_client_payeur">Nom du client</label>
            <input
              type="text"
              id="nom_client_payeur"
              name="nom_client_payeur"
              value={formData.nom_client_payeur}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="modal-actions">
          <button 
            type="button" 
            onClick={onClose}
            className="cancel-button"
            disabled={loading}
          >
            Annuler
          </button>
          <button 
            type="submit" 
            className="submit-button"
            disabled={loading}
          >
            {loading ? 'Mise à jour...' : 'Enregistrer'}
          </button>
        </div>
      </form>
    </div>
  </div>

      <DuplicateModal
        isOpen={isDuplicateModalOpen}
        onClose={() => setIsDuplicateModalOpen(false)}
        onConfirm={handleDuplicate}
      />
    </>
  );
};

export default EditFactureModal;