import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import Paiement from './PopupPayement'; 

const PayeDirect = () => {
    const { invoiceNumber } = useParams();
    const [invoices, setInvoices] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const response = await fetch(
                    `http://localhost:5000/api/factures/ouvrefacture/${invoiceNumber}`,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des factures');
                }
                const result = await response.json();
                console.log("Factures reçues:", result);

                if (!result || result.length === 0) {
                    throw new Error('Aucune facture trouvée');
                }

                setInvoices(result);

            } catch (err) {
                console.error("Erreur:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (invoiceNumber) {
            fetchInvoices();
        }
    }, [invoiceNumber]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="flex flex-col items-center gap-2">
                    <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                    <p className="text-gray-600 text-sm">
                        Chargement des factures...
                    </p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-md mx-auto mt-8 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg">
                <p className="text-sm font-medium">{error}</p>
                <button 
                    onClick={() => window.history.back()} 
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                    Retour
                </button>
            </div>
        );
    }

    if (invoices && invoices.length > 0) {
        return (
            <div className="min-h-screen bg-gray-100">
                <Paiement 
                    factures={invoices}
                    onClose={() => window.history.back()}
                    onPaymentComplete={() => {
                        console.log("Paiement complété");
                    }}
                />
            </div>
        );
    }

    return <Navigate to="/" replace />;
};

export default PayeDirect;