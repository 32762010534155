import React, { useState, useContext } from 'react';
import { getReponseApi } from './apis/api_backend';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { PayEcoleContext } from '../contexts/PayEcoleContext';
import { User, Lock, Key, Building, ArrowRight, Sparkles } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import Header from './Header';


const Login = () => {
  const [loginAgent, setLoginAgent] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  const { 
    setUser, 
    setConnectedParent, 
    setIsAdmin, 
    setStructure 
  } = useContext(PayEcoleContext);
  
  const { login } = useContext(AuthContext);

  const afficherUser = async (user) => {
    try {
      const isAdminGroup = [-1, 0].includes(user.id_groupe);
      setIsAdmin(isAdminGroup);
      
      if (!isAdminGroup) {
        await loadStructure(user.id_structure);
      }
      
      redirectUser(user.profil);
    } catch (error) {
      console.error("Erreur lors du traitement de l'utilisateur:", error);
      setErrorMessage("Erreur lors du chargement des données utilisateur");
    }
  };

  const redirectUser = (profil) => {
    const routes = {
      SCOLAIRE: '/mastructure',
      COMMERCE: '/mastructure',
      CLIENT: '/homeclient',
      ADMIN: '/systeme'
    };
    
    const route = routes[profil] || '/nobody';
    navigate(route);
  };

  const loadStructure = async (pid_structure) => {
    try {
      const response = await getReponseApi(`/structures/${pid_structure}`, 'GET');
      if (response && response[0]) {
        const params = response[0];

        switch (params.type_structure) {
          case 'SCOLAIRE':
          case 'COMMERCIALE':
          case 'FORMATION PRO':
          case 'PRESTATAIRE DE SERVICES':
            setStructure(params);
            break;
          case 'GENERAL':
            setConnectedParent(params);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des informations de la structure:", error);
      throw new Error("Impossible de charger les informations de la structure");
    }
  };
  const handleNavigate = () => {
    navigate('/inscription');
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await getReponseApi('/utilisateurs/login', 'POST', {
        login: loginAgent,
        pwd: password
      });

      const { token, user } = response;

      if (user && token) {
        localStorage.setItem('token', token);
        login(user);
        setUser(user);
        await afficherUser(user);
      } else {
        setErrorMessage("Identifiants incorrects. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur de connexion:", error);
      setErrorMessage("Impossible de se connecter. Veuillez vérifier votre connexion.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <Header />
      <div className="login-background">
        <div className="login-container">
          <div className="login-card">
            <div className="login-card-image-container">
              <img
                className="login-card-image"
                src={`${process.env.PUBLIC_URL}/images/user-icon.png`}
                alt="User Icon"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/default-avatar.png";
                }}
              />
            </div>
            <div className="login-card-body">
              <h2>Bienvenue</h2>
              {errorMessage && (
                <div className="text-danger">
                  {errorMessage}
                </div>
              )}
              <form onSubmit={handleLogin}>
                <div className="loginform-group">
                  <div className="input-icon-wrapper">
                    <User className="input-icon" size={20} />
                    <input
                      type="text"
                      className="logininput"
                      placeholder="Entrez votre identifiant"
                      value={loginAgent}
                      onChange={(e) => setLoginAgent(e.target.value)}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="loginform-group">
                  <div className="input-icon-wrapper">
                    <Lock className="input-icon" size={20} />
                    <input
                      type="password"
                      className="logininput"
                      placeholder="Entrez votre mot de passe"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="loginrounded-button"
                  disabled={isLoading}
                >
                  <Key className="button-icon" size={20} />
                  {isLoading ? 'Connexion...' : 'Se connecter'}
                  <div className="button-shine"></div>
                </button>
              </form>

              <div className="login-links">
                <a href="/forgot-password" className="login-link forgot-password-link">
                  <Lock className="link-icon" size={16} />
                  Mot de passe perdu ?
                </a>
                <div className="create-structure-container" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                  <div className="create-structure-button">
                    <div className="button-content">
                      <Building className="structure-icon" size={24} />
                      <span>Créer ma structure</span>
                      <ArrowRight className="arrow-icon" size={20} />
                    </div>
                    <div className="sparkles">
                      <Sparkles className="sparkle-icon" size={16} />
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;