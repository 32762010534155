// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import SessionTimeout from './contexts/SessionTimeout';

import HomePage from './components/Home';
import Login from './components/Login';
import Compte from './components/Admin/MonCompte';
import About from './components/About';
import ConventionForm from './components/Admin/Convention/ConventionForm';
import PayeDirect from './components/Admin/PayeDirect';


import Listfactures from  './components/Admin/ListFactures';
import PayFacture from './components/Admin/jepay';
import PopPayement from './components/Admin/PopupPayement';
import PopTicketRecu from './components/Admin/TicketRecu';
import SuccessPayment from './components/SuccessPayment';

import { PayEcoleProvider } from './contexts/PayEcoleContext';
import { AuthProvider } from './contexts/AuthContext';
import  NewStructure  from './components/Admin/NewStructure';
import EditStructure from './components/Admin/EditStructure';
import UneStructure from './components/Admin/UneStructure';



function App() {
    return (
        <AuthProvider>
        <PayEcoleProvider>
          <Router>
            <SessionTimeout timeout={180000} />
            
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/apropos" element={<About />} />
              <Route path="/inscription" element={<NewStructure />} />
              <Route path="/jepaye" element={<PayFacture />} />
              <Route path="/recu" element={<PopTicketRecu />} />
              <Route path="/popup" element={<PopPayement />} />
              <Route path="/convention" element={<ConventionForm />} />
              <Route path="/success/:num_facture/:ref_destinate" element={<SuccessPayment />} />
              <Route 
                            path="/facture/:invoiceNumber" 
                            element={<PayeDirect />} 
                        />
         
              {/* Routes protégées */}
              <Route element={<ProtectedRoute />}>
                <Route path="/compte" element={<Compte />} />
                <Route path="/mastructure" element={<UneStructure />} />
                <Route path="/edit_structure" element={<EditStructure />} />
                <Route path="/paiements" element={<Listfactures />} />
              </Route>
            </Routes>
          </Router>
        </PayEcoleProvider>
      </AuthProvider>
    );
}

export default App;
