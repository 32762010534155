import React, { useState, useEffect, useContext, useCallback } from 'react';
import { getReponseApi } from '../apis/api_backend';
import Header from '../Header';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faCalendarAlt, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import EditFactureModal from './EditFactureModal';
import ArticleManagerModal from './ArticleManagerModal';
import '../styles/ListFactures.css';

const ListFactures = () => {
  // États existants conservés
  const [factures, setFactures] = useState([]);
  const [filteredFactures, setFilteredFactures] = useState([]);
  const [isArticleManagerOpen, setIsArticleManagerOpen] = useState(false);
  const [filters, setFilters] = useState({
    nom_structure: '',
    date_debut_facture: '',
    date_fin_facture: '',
    libelle_etat: '',
    tel_client: '',
    nom_client: '',
    nom_classe: '',
    nom_classe_saisi: '' 
  });
  const [currentPage, setCurrentPage] = useState(1);
  const facturesPerPage = 15;
  const { user } = useContext(PayEcoleContext);

  const [articles, setArticles] = useState([]);

  // Nouveaux états pour le modal
  const [showModal, setShowModal] = useState(false);
  const [editingFacture, setEditingFacture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fonction existante fetchFactures conservée
  const fetchFactures = useCallback(async () => {
    console.log('Chargement des factures...');
    if (!user.id_structure) return;

    try {
      const body = { id_structure: user.id_structure };
      const response = await getReponseApi(`/factures/getall`, 'POST', body);
      if (response) {
        setFactures(response.data);
        setFilteredFactures(response.data);
        const uniqueArticles = getUniqueArticles(response.data);
        setArticles(uniqueArticles);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  }, [user]);

  // Utilisation des fonctions existantes
  useEffect(() => {
    fetchFactures();
  }, [fetchFactures]);

  const nomClasses = articles.map(article => article.nom_article);
  const getUniqueArticles = (factures) => {
    // Créer un Map pour stocker les articles uniques avec leur dernière information de prix
    const articlesMap = new Map();
  
    // Premier passage pour compter les occurrences
    const countMap = new Map();
    factures.forEach(facture => {
      if (facture.nom_classe) {
        countMap.set(
          facture.nom_classe, 
          (countMap.get(facture.nom_classe) || 0) + 1
        );
      }
    });
  
    // Deuxième passage pour créer les objets avec toutes les informations
    factures.forEach(facture => {
      if (facture.nom_classe) {
        articlesMap.set(facture.nom_classe, {
          nom_article: facture.nom_classe,
          montant: facture.montant,
          id_structure: facture.id_structure,
          qte: countMap.get(facture.nom_classe)
        });
      }
    });
  
    return Array.from(articlesMap.values())
      .sort((a, b) => a.nom_article.localeCompare(b.nom_article, 'fr', { sensitivity: 'base' }));
  };
  
  // Nouvelles fonctions pour la gestion des factures
  const handleEditClick = (facture) => {
    setEditingFacture(facture);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingFacture(null);
  };

    // Fonction de mise à jour modifiée
    const handleUpdateFacture = async (factureId, updatedData) => {
      try {
        // Rafraîchir les données
        await fetchFactures();
        alert('Facture mise à jour avec succès');
      } catch (error) {
        console.error('Erreur lors du rafraîchissement des données:', error);
      }
    };

  // Fonction de suppression modifiée
  const handleDeleteFacture = async (factureId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette facture ?')) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await getReponseApi(`/factures/${factureId}`, 'DELETE');
      if (response.success) {
        // Rafraîchir les données après la suppression
        await fetchFactures();
        alert('Facture supprimée avec succès');
      } else {
        throw new Error(response.error || 'Erreur lors de la suppression');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      alert(error.message || 'Erreur lors de la suppression de la facture');
    } finally {
      setIsLoading(false);
    }
  };


  const formatPeriode = (mois, annee) => {
    // Validation des entrées
    if (!mois || !annee) return 'Période non définie';
    
    // Conversion en nombres pour validation
    const moisNum = parseInt(mois);
    const anneeNum = parseInt(annee);
    
    // Validation des valeurs
    if (isNaN(moisNum) || isNaN(anneeNum) || 
        moisNum < 1 || moisNum > 12 || 
        anneeNum < 2000 || anneeNum > 2100) {
      return 'Période invalide';
    }
  
    const moisNoms = {
      1: 'Jan', 2: 'Fév', 3: 'Mar', 4: 'Avr',
      5: 'Mai', 6: 'Juin', 7: 'Juil', 8: 'Aoû',
      9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Déc'
    };
  
    return `${moisNoms[moisNum]}-${anneeNum}`;
  };
  
  
  // Gérer les changements dans les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
      

    }));
  };

  // Appliquer le filtrage lorsque les filtres ou les factures changent
  useEffect(() => {
    let result = factures;
    
    if (filters.nom_structure) result = result.filter(facture => facture.nom_structure.includes(filters.nom_structure));
    
    // Filtre par select
    if (filters.nom_classe) result = result.filter(facture => facture.nom_classe === filters.nom_classe);
    
    // Filtre par saisie avec log
    if (filters.nom_classe_saisi) {
      console.log('Tentative de filtrage par nom_classe_saisi');
      result = result.filter(facture => {
        const matchFound = facture.nom_classe && 
                         facture.nom_classe.toLowerCase().includes(filters.nom_classe_saisi.toLowerCase());
       
        return matchFound;
      });
    }

    if (filters.date_debut_facture && filters.date_fin_facture) {
      result = result.filter(facture => {
        const factureDate = new Date(facture.date_facture);
        const dateDebut = new Date(filters.date_debut_facture);
        const dateFin = new Date(filters.date_fin_facture);
        return factureDate >= dateDebut && factureDate <= dateFin;
      });
    }
    if (filters.libelle_etat) result = result.filter(facture => facture.libelle_etat === filters.libelle_etat);
    if (filters.tel_client) result = result.filter(facture => facture.tel_client.includes(filters.tel_client));
    if (filters.nom_client) result = result.filter(facture => facture.nom_client.toLowerCase().includes(filters.nom_client.toLowerCase()));
    
    
    setFilteredFactures(result);
    setCurrentPage(1);
  }, [filters, factures]);

  // Pagination
  const indexOfLastFacture = currentPage * facturesPerPage;
  const indexOfFirstFacture = indexOfLastFacture - facturesPerPage;
  const currentFactures = filteredFactures.slice(indexOfFirstFacture, indexOfLastFacture);

  const totalPages = Math.ceil(filteredFactures.length / facturesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  // Calculer les statistiques des factures
  const totalFactures = filteredFactures.length;
  const totalMontantPayees = filteredFactures.filter(f => f.libelle_etat === 'PAYEE').reduce((acc, f) => acc + parseFloat(f.montant), 0);
  const totalMontantImpayees = filteredFactures.filter(f => f.libelle_etat === 'IMPAYEE').reduce((acc, f) => acc + parseFloat(f.montant), 0);

  return (
    <div className="list-factures">
      <Header />

      {/* Filtres pour les factures */}
      <div className="filters">
        <select name="nom_structure" onChange={handleFilterChange}>
          <option value="">Toutes les structures</option>
          {/* Ajouter ici des options pour chaque structure */}
        </select>
        <input
          type="date"
          name="date_debut_facture"
          placeholder="Date début"
          onChange={handleFilterChange}
        />
        <input
          type="date"
          name="date_fin_facture"
          placeholder="Date fin"
          onChange={handleFilterChange}
        />
        <select name="libelle_etat" onChange={handleFilterChange}>
          <option value="">Tous les états</option>
          <option value="PAYEE">Payée</option>
          <option value="IMPAYEE">Impayée</option>
        </select>
        <input
          type="text"
          name="tel_client"
          placeholder="Téléphone client"
          onChange={handleFilterChange}
        />
        <input
          type="text"
          name="nom_client"
          placeholder="Nom du client"
          onChange={handleFilterChange}
        />
        {/* Filtre par nom_classe */}
        <select name="nom_classe" onChange={handleFilterChange}>
          <option value="">{user?.profil === 'SCOLAIRE' ? 'Toutes les classes' : 'Toutes les articles'}</option>
          {nomClasses.map((classe, index) => (
            <option key={index} value={classe}>{classe}</option>
          ))}
        </select>
        <input
          type="text"
          name="nom_classe_saisi"
          placeholder={user?.profil === 'SCOLAIRE' ? 'Nom de la classe' : 'Nom du produit/service'}
          onChange={handleFilterChange}
       
        />
      </div>

      {/* Statistiques des factures */}
      <div className="factures-stats">
        <p>Total factures: {totalFactures}</p>
        <p>Montant total des factures payées: {totalMontantPayees} FCFA</p>
        <p>Montant total des factures impayées: {totalMontantImpayees} FCFA</p>
      </div>
      <button 
      className="manage-articles-button"
      onClick={() => setIsArticleManagerOpen(true)}
    >
      Gérer mes Articles
    </button>
      {/* Pagination */}
      <div className="pagination-controls">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>Début</button>
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>Précédent</button>
        <span>Page {currentPage} sur {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>Suivant</button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>Fin</button>
      </div>

    {/* Liste des factures modifiée */}
    <div className="factures-container">
        {currentFactures.map(facture => (
          <div 
            key={facture.id_facture} 
            className="facture-card"
            onClick={(e) => {
              // Éviter d'ouvrir le modal si on clique sur les boutons
              if (!e.target.closest('.facture-actions')) {
                handleEditClick(facture);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {/* Indicateur visuel pour montrer que la carte est cliquable */}
            <div className="hover-overlay" />
            
            <h3>{facture.num_facture} - {facture.nom_classe}</h3>
            <p><FontAwesomeIcon icon={faCalendarAlt} /> Période: {formatPeriode(facture.nmois, facture.nannee)}</p>
            <p><FontAwesomeIcon icon={faUser} /> Client: {facture.nom_client}</p>
            <p><FontAwesomeIcon icon={faPhone} /> Téléphone: {facture.tel_client}</p>
            <p><FontAwesomeIcon icon={faFileInvoiceDollar} /> Montant: {facture.montant} FCFA</p>
            <p>
              <span className={`etat-badge ${facture.libelle_etat.toLowerCase()}`}>
                {facture.libelle_etat}
              </span>
            </p>
            
            {/* Les boutons d'action dans un conteneur séparé pour éviter la propagation du clic */}
            <div className="facture-actions" onClick={e => e.stopPropagation()}>
              <button 
                className="facture-card-edit" 
                onClick={() => handleEditClick(facture)}
                disabled={isLoading}
              >
                Éditer la facture
              </button>
              <button 
                className="facture-card-delete" 
                onClick={() => handleDeleteFacture(facture.id_facture)}
                disabled={facture.id_etat !== 1 || isLoading}
              >
                Supprimer la facture
              </button>
            </div>
          </div>
        ))}
      </div>          
         {/* Modal d'édition */}
       <EditFactureModal
        facture={editingFacture}
        isOpen={showModal}
        onClose={handleCloseModal}
        onUpdate={handleUpdateFacture}
        isLoading={isLoading}
        />
     <ArticleManagerModal
        isOpen={isArticleManagerOpen}
        onClose={() => setIsArticleManagerOpen(false)}
        articles={articles}
        structure={user}
      />
    </div>
  );
};

export default ListFactures;