import React, { createContext, useState, useCallback } from 'react';

// Création du contexte
export const PayEcoleContext = createContext();

// Fournisseur de contexte
export const PayEcoleProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [structure, setStructure] = useState(null);
  const [appParams, setAppParams] = useState({});
  const [connectedParent, setConnectedParent] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Fonction de déconnexion avec log
  // Fonction de déconnexion
const logout = useCallback(() => {
  console.log("Déconnexion en cours. Utilisateur actuel :", user);
  
  // Réinitialisation des états
  setUser(null);
  setStructure(null);
  setAppParams({});
  setConnectedParent(null);
  setIsAdmin(false);

  console.log("Déconnexion réussie. Tous les états réinitialisés.");
}, [user]);

 // Fonction de mise à jour de l'utilisateur avec log
 const updateUser = useCallback((newUser) => {
  console.log("Mise à jour de l'utilisateur :", newUser);
  setUser(newUser);
}, []);

  // Fonction de mise à jour de la structure avec log
  const updateStructure = useCallback((newStructure) => {
    console.log("Mise à jour de la structure :", newStructure);
   
    if (newStructure?.type_structure) {
      switch (newStructure.type_structure) {
        case 'SCOLAIRE':
        case 'COMMERCE':
        case 'FORMATION PRO':
        case 'PRESTATAIRE DE SERVICES':
          setIsAdmin(false);
          setStructure(newStructure);
          break;
        case 'CLIENT':
          setConnectedParent(newStructure);
          break;
          case 'SYSTEM':
            setConnectedParent(newStructure);
            setIsAdmin(true);
            break;
        default:
          break;
      }
    }
  }, []);

  return (
    <PayEcoleContext.Provider value={{
      user,
      setUser: updateUser,
      structure,
      setStructure: updateStructure,
      appParams,
      setAppParams,
      connectedParent,
      setConnectedParent,
      isAdmin,
      setIsAdmin,
      logout
    }}>
      {children}
    </PayEcoleContext.Provider>
  );
};
