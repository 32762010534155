import React, { useState,useEffect,useCallback } from 'react';
import '../styles/jepay.css';
import { getReponseApi } from '../apis/api_backend';
import Header from '../Header';
import PopupPayement from './PopupPayement';
import TicketRecu from './TicketRecu';
import FilterSelects from './FilterSelects';
import { Search, Phone, FileText, CreditCard, Download, AlertCircle, CheckCircle, ShoppingCart } from 'lucide-react';


const CART_KEY = 'fayclick_cart';

const getCartFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(CART_KEY)) || [];
  } catch {
    return [];
  }
};

const saveCartToStorage = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

const formatPeriode = (mois, annee) => {
  const moisNoms = {
    '1': 'JAN',
    '2': 'FEV',
    '3': 'MAR',
    '4': 'AVR',
    '5': 'MAI',
    '6': 'JUN',
    '7': 'JUL',
    '8': 'AOU',
    '9': 'SEP',
    '10': 'OCT',
    '11': 'NOV',
    '12': 'DEC'
  };
  
  // Assurez-vous que mois est une chaîne
  const moisStr = mois.toString();
  return `${moisNoms[moisStr]}-${annee}`;
};

const calculerTotal = (selectedIds, factures) => {
  if (!factures || factures.length === 0) return 0;

  return selectedIds.reduce((total, id) => {
    const facture = factures.find(f => f.id_facture === id);
    const montant = facture ? parseFloat(facture.montant) : 0;

    // Vérifier si montant est un nombre avant de l'ajouter au total
    return !isNaN(montant) ? total + montant : total;
  }, 0);
};
const FacturesTable = ({ 
  filteredFactures, selectedFactures, handleCheckboxChange,
  handleRowClick, handleRecuClick, formatMontant,
  calculerTotal, handlePayMultiple, factures
}) => {
  const handlePaymentAttempt = () => {
    const montantTotal = calculerTotal(selectedFactures, factures);
    if (montantTotal > 200000) {
      alert(`Le montant total de ${Math.floor(montantTotal).toLocaleString('fr-FR')} FCFA dépasse la limite autorisée de 200 000 FCFA.\n\nVeuillez réduire le nombre de factures sélectionnées.`);
      return;
    }
    handlePayMultiple();
  };

  return (
    <div className="factures-table-container">
      {selectedFactures.length > 0 && (
        <div className="cart-summary">
          <ShoppingCart size={20} />
          <span>{selectedFactures.length} facture(s) sélectionnée(s)</span>
          <span className="cart-total">{formatMontant(calculerTotal(selectedFactures, factures))}</span>
          <button
            className="pay-multiple-button"
            onClick={handlePaymentAttempt}
          >
            <CreditCard size={18} />
            Procéder au paiement ({formatMontant(calculerTotal(selectedFactures, factures))})
          </button>
        </div>
      )}
      <table className="factures-table">
        <thead>
          <tr>
            <th></th>
            <th>Période</th>
            <th>Description</th>
            <th>Montant</th>
            <th className="hide-mobile">État</th>
            <th className="hide-mobile">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredFactures.map((facture) => (
            <tr 
              key={facture.id_facture}
              className={`facture-row ${facture.id_etat === 1 ? 'unpaid-row' : 'paid-row'}`}
              onClick={() => handleRowClick(facture)}
            >
              <td onClick={(e) => e.stopPropagation()}>
                {facture.id_etat === 1 && (
                  <input
                    type="checkbox"
                    checked={selectedFactures.includes(facture.id_facture)}
                    onChange={() => handleCheckboxChange(facture.id_facture)}
                    className="facture-checkbox"
                  />
                )}
              </td>
              <td>{formatPeriode(facture.nmois, facture.nannee)}</td>
              <td>
                <div className="description-cell">
                  <span className="description-text">
                    {facture.nom_classe + ' : ' + facture.nom_client}
                  </span>
                  <span className="mobile-status">
                    {facture.id_etat === 1 ? (
                      <span className="status-indicator unpaid">Pas encore</span>
                    ) : (
                      <span className="status-indicator paid">Payée</span>
                    )}
                  </span>
                </div>
              </td>
              <td className="amount">{formatMontant(facture.montant)}</td>
              <td className="hide-mobile">
                <span className={`status-badge ${facture.id_etat === 1 ? 'unpaid' : 'paid'}`}>
                  {facture.id_etat === 1 ? 'À payer' : 'Payée'}
                </span>
              </td>
              <td className="hide-mobile">
                {facture.id_etat !== 1 && (
                  <button 
                    className="action-button receipt"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRecuClick(facture);
                    }}
                  >
                    <Download size={16} />
                    <span className="button-text">Reçu</span>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const SearchSection = ({ 
  searchBy, setSearchBy, 
  numtelclient, numfacture, 
  handleInputChange, handleSearch,
  loading, error, setNumTelClient, setNumFacture 
}) => (
  <div className="search-card">
    <div className="search-options">
      <label className={`search-option ${searchBy === 'tel' ? 'active' : ''}`}>
        <input 
          type="radio" 
          name="searchBy" 
          value="tel"
          checked={searchBy === 'tel'}
          onChange={() => setSearchBy('tel')}
        />
        <Phone size={20} />
        <span>Recherche par N°Tél</span>
      </label>
      <label className={`search-option ${searchBy === 'facture' ? 'active' : ''}`}>
        <input 
          type="radio" 
          name="searchBy" 
          value="facture"
          checked={searchBy === 'facture'}
          onChange={() => setSearchBy('facture')}
        />
        <FileText size={20} />
        <span>Recherche par N° Facture</span>
      </label>
    </div>

    <div className="search-input-container">
      {searchBy === 'tel' && (
        <div className="input-wrapper">
          <Phone size={20} className="input-icon" />
          <input 
            type="text" 
            placeholder="Entrez votre numéro de téléphone" 
            value={numtelclient} 
            onChange={handleInputChange(setNumTelClient)} 
          />
        </div>
      )}
      {searchBy === 'facture' && (
        <div className="input-wrapper">
          <FileText size={20} className="input-icon" />
          <input 
            type="text" 
            placeholder="Entrez le numéro de facture" 
            value={numfacture} 
            onChange={handleInputChange(setNumFacture)} 
          />
        </div>
      )}
      <button 
        className="search-button"
        onClick={handleSearch} 
        disabled={loading}
      >
        {loading ? (
          <div className="loader">Recherche en cours...</div>
        ) : (
          <>
            <Search size={20} />
            <span>Rechercher</span>
          </>
        )}
      </button>
    </div>

    {error && (
      <div className="error-message">
        <span>⚠️</span> {error}
      </div>
    )}
  </div>
);

const StatsFilter = ({ factures, filteredFactures, activeFilter, onFilterChange }) => {
  // Calculer les statistiques basées sur les factures filtrées
  const paidCount = filteredFactures.filter(f => f.id_etat !== 1).length;
  const unpaidCount = filteredFactures.filter(f => f.id_etat === 1).length;
  const totalCount = filteredFactures.length;

  return (
    <div className="stats-filter-container">
      <button 
        className={`stat-card total ${activeFilter === '' ? 'active' : ''}`}
        onClick={() => onFilterChange('')}
      >
        <div className="stat-icon">
          <FileText size={16} />
        </div>
        <div className="stat-info">
          <span className="stat-value">{totalCount}</span>
          <span className="stat-label">Total factures</span>
        </div>
      </button>

      <button 
        className={`stat-card unpaid ${activeFilter === 'impayee' ? 'active' : ''}`}
        onClick={() => onFilterChange('impayee')}
      >
        <div className="stat-icon">
          <AlertCircle size={16} />
        </div>
        <div className="stat-info">
          <span className="stat-value">{unpaidCount}</span>
          <span className="stat-label">À payer</span>
        </div>
      </button>

      <button 
        className={`stat-card paid ${activeFilter === 'payee' ? 'active' : ''}`}
        onClick={() => onFilterChange('payee')}
      >
        <div className="stat-icon">
          <CheckCircle size={16} />
        </div>
        <div className="stat-info">
          <span className="stat-value">{paidCount}</span>
          <span className="stat-label">Payées</span>
        </div>
      </button>
    </div>
  );
};


const Jepay = () => {
  const [factures, setFactures] = useState([]);

  const [filteredFactures, setFilteredFactures] = useState([]);
  const [numtelclient, setNumTelClient] = useState('');
  const [numfacture, setNumFacture] = useState('');
  const [searchBy, setSearchBy] = useState('tel');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [etatFilter, setEtatFilter] = useState('');
  const [popupType, setPopupType] = useState(null);
  const [selectedFacture, setSelectedFacture] = useState(null);

  const [selectedFactures, setSelectedFactures] = useState([]);

  const [structures, setStructures] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState('');
  const [selectedClasse, setSelectedClasse] = useState('');
  
  // Fonction pour extraire les structures et classes uniques des factures
  const updateFilterOptions = useCallback((factures) => {
    const uniqueStructures = [...new Set(factures.map(f => f.nom_structure))];
    const uniqueClasses = [...new Set(factures.map(f => f.nom_classe))];
    
    setStructures(uniqueStructures);
    setClasses(uniqueClasses);
  }, []);

  const formatMontant = (montant) => {
    return Math.floor(montant).toLocaleString('fr-FR', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' FCFA';
  };

  
    const handleSearch = async () => {
      setLoading(true);
      setError(null);
      setShowSelect(false);
      setSelectedFactures([]);
      setSelectedStructure('');
      setSelectedClasse('');
      saveCartToStorage([]);
      
      const body = {
        numtelclient: searchBy === 'tel' ? numtelclient : '',
        numfacture: searchBy === 'facture' ? numfacture : ''
      };

    try {
      const response = await getReponseApi('/factures/trouverfacture', 'POST', body);
      if (response && Array.isArray(response)) {
        setFactures(response);
        setFilteredFactures(response);
        updateFilterOptions(response);
        setShowSelect(true);
      } else {
        setFactures([]);
        setFilteredFactures([]);
        setShowSelect(false);
        setError('Aucune facture trouvée');
      }
    } catch (error) {
      setError('Erreur lors de la récupération des factures. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (factures.length > 0) {
      // Gestion du panier
      const savedCart = getCartFromStorage();
      const validCart = savedCart.filter(id => 
        factures.some(f => f.id_facture === id)
      );
      setSelectedFactures(validCart);
      saveCartToStorage(validCart);
  
      // Application des filtres
      let result = [...factures];
      
      if (selectedStructure) {
        result = result.filter(f => f.nom_structure === selectedStructure);
      }
      
      if (selectedClasse) {
        result = result.filter(f => f.nom_classe === selectedClasse);
      }
      
      if (etatFilter === 'impayee') {
        result = result.filter(f => f.id_etat === 1);
      } else if (etatFilter === 'payee') {
        result = result.filter(f => f.id_etat !== 1);
      }
      
      setFilteredFactures(result);
    }
  }, [factures, selectedStructure, selectedClasse, etatFilter]);


  const handleCheckboxChange = (factureId) => {
    const newSelection = selectedFactures.includes(factureId)
      ? selectedFactures.filter(id => id !== factureId)
      : [...selectedFactures, factureId];
    
    setSelectedFactures(newSelection);
    saveCartToStorage(newSelection);
  };
  
  
  const handlePayMultiple = () => {
    // Récupérer d'abord les objets factures complets
    const facturesToPay = factures.filter(facture => 
      selectedFactures.includes(facture.id_facture)
    );
  
  
    if (facturesToPay.length > 0) {
      setSelectedFacture(facturesToPay); // On passe le tableau de factures complet
    
      setPopupType('paiement');
    } else {
      console.error('Aucune facture trouvée pour les IDs sélectionnés');
    }
  };
      // Gestionnaire de clic sur une ligne
      const handleRowClick = (facture) => {
        if (facture.id_etat !== 1) {
          handleRecuClick(facture);
        }
      };
  


  const handleRecuClick = (facture) => {
    setSelectedFacture(facture);
    setPopupType('recu');
  };

  const closePopup = () => {
    setSelectedFacture(null);
    setPopupType(null);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setShowSelect(false);
    setEtatFilter('');
  };



  const handleEtatChange = (e) => {
    const selectedEtat = e.target.value;
    setEtatFilter(selectedEtat);
    setFilteredFactures(
      selectedEtat === 'impayee' 
        ? factures.filter(facture => facture.id_etat === 1)
        : selectedEtat === 'payee'
          ? factures.filter(facture => facture.id_etat !== 1)
          : factures
    );
  };


useEffect(() => {
  if (factures.length > 0) {
    const savedCart = getCartFromStorage();
    const validCart = savedCart.filter(id => 
      factures.some(f => f.id_facture === id)
    );
    setSelectedFactures(validCart);
    saveCartToStorage(validCart);

    // Application des filtres
    let result = [...factures];
    
    if (selectedStructure) {
      result = result.filter(f => f.nom_structure === selectedStructure);
    }
    
    if (selectedClasse) {
      result = result.filter(f => f.nom_classe === selectedClasse);
    }
    
    if (etatFilter === 'impayee') {
      result = result.filter(f => f.id_etat === 1);
    } else if (etatFilter === 'payee') {
      result = result.filter(f => f.id_etat !== 1);
    }
    
    setFilteredFactures(result);
  }
}, [factures, selectedStructure, selectedClasse, etatFilter]);

return (
  <div className="page-container">
    <Header />
    <div className="container">
      <div className="welcome-section">
        <h1>Retrouvez et payez toutes vos factures en quelques clics</h1>
      </div>

      <SearchSection 
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        numtelclient={numtelclient}
        numfacture={numfacture}
        handleInputChange={handleInputChange}
        handleSearch={handleSearch}
        loading={loading}
        error={error}
        setNumTelClient={setNumTelClient}
        setNumFacture={setNumFacture}
      />

      {showSelect && factures.length > 0 && (
        <>
          <StatsFilter 
            factures={factures}
            filteredFactures={filteredFactures} // Ajout des factures filtrées
            activeFilter={etatFilter}
            onFilterChange={(value) => handleEtatChange({ target: { value }})}
          />
          <FilterSelects 
            selectedStructure={selectedStructure}
            selectedClasse={selectedClasse}
            structures={structures}
            classes={classes}
            setSelectedStructure={setSelectedStructure}
            setSelectedClasse={setSelectedClasse}
          />
        </>
      )}
      {filteredFactures.length > 0 && (
        <FacturesTable 
          filteredFactures={filteredFactures}
          selectedFactures={selectedFactures}
          handleCheckboxChange={handleCheckboxChange}
          handleRowClick={handleRowClick}
          handleRecuClick={handleRecuClick}
          formatMontant={formatMontant}
          calculerTotal={calculerTotal}
          handlePayMultiple={handlePayMultiple}
          factures={factures}
        />
      )}

      {selectedFacture && popupType === 'paiement' && (
        <PopupPayement 
          factures={selectedFacture}
          onClose={() => {
            closePopup();
            setSelectedFactures([]);
            saveCartToStorage([]);
          }} 
        />
      )}

      {selectedFacture && popupType === 'recu' && (
        <TicketRecu 
          facture={selectedFacture} 
          onClose={closePopup} 
        />
      )}

      <div className="secure-payment-info">
        <div className="security-badge">
          <span className="security-icon">🔒</span>
          <p>Paiements sécurisés via Orange Money</p>
        </div>
      </div>
    </div>
  </div>
);
};

export default Jepay;